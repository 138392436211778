import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from '../components/Hero'
import FeatureOverview from '../components/FeatureOverview'
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import Seo from "../components/Seo"

const IndexPage = () => {
    const socialImage = {
        src: "/social.png",
        height: 1000,
        width: 3025
    }
    
    return (
        <Layout headerClassName='bg-rickshaw-blue text-white border-white'>
            <Seo 
                title="Last mile transport for your Stripe notifications"
                description="Last mile transport for your Stripe notifications. Rickshaw delivers messages on your behalf on Stripe events like payments, subscriptions and refunds."
                image={socialImage}
            />
            <div className="w-full bg-rickshaw-blue">
                <Hero />
            </div>
            <div>
                <p className="my-8 text-xl font-semibold text-center lg:my-12 text-rickshaw-blue">Rickshaw delivers Email &amp; SMS messages to your customers on Stripe events.</p>
            </div>
            <FeatureOverview />
            <PricingBanner />
        </Layout>
    );
};

export default IndexPage;
