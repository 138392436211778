import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const HeroComponent = () => {
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "hero.svg" }) {
                publicURL
            },
            heroMobile: file(relativePath: { eq: "hero-mobile.svg" }) {
                publicURL
            }
        }
    `);

    return (
        <div className='flex flex-col items-center pb-8 text-center text-white md:m-auto md:max-w-screen-xl'>
            <h2 className='text-2xl font-light md:text-3xl'>
                You set the routes. We handle the journeys.
            </h2>
            <h1 className="my-4 text-3xl font-semibold">
                Last mile transport for your Stripe notifications
            </h1>
            <img src={data.hero.publicURL} height={600} className="hidden mt-24 lg:block" alt="Hero illustration" />
            <img src={data.heroMobile.publicURL} height={600} className="mt-24 lg:hidden" alt="Hero illustration" />
        </div>
    );
};
export default HeroComponent;
