import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const FeatureOverview = () => {
    const data = useStaticQuery(graphql`
        query {
            pickup: file(relativePath: { eq: "icon-pickup.svg" }) {
                publicURL
            }
            delivery: file(relativePath: { eq: "icon-delivery.svg" }) {
                publicURL
            }
            package: file(relativePath: { eq: "icon-package.svg" }) {
                publicURL
            }
            report: file(relativePath: { eq: "icon-report.svg" }) {
                publicURL
            }
        }
    `);
    return (
        <section className='max-w-screen-lg mx-auto'>
            <ul className='grid grid-cols-1 mx-4 mt-4 md:grid-cols-2 gap-x-20 md:pl-10'>
                <li>
                    <img src={data.pickup.publicURL} height={200} width={200} alt='Pickup' />
                    <h3 className='mt-4 text-4xl font-bold text-rickshaw-blue'>Pickup</h3>
                    <p className='my-4 text-base'>
                        Your Stripe accounts{' '}
                        <a
                            href='https://stripe.com/docs/api/events/types'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='underline text-rickshaw-blue'
                        >
                            events
                        </a>{' '}
                        are securely picked up by Rickshaw. For example when you add a tax ID to your Stripe's customer
                        profile,{' '}
                        <span className='border py-0.5 px-1.5 border-rickshaw-lightgrey font-mono'>
                            invoice.paid
                        </span>{' '}
                        is fired. Our high availability environment picks that up and bring it home for packaging.
                    </p>
                </li>
                <li>
                    <img src={data.package.publicURL} height={160} width={160} alt='Package' />
                    <h3 className='mt-4 text-4xl font-bold text-rickshaw-blue'>Package</h3>
                    <p className='my-4 text-base text-rickshaw-darkgrey'>
                        Rickshaw retrieves relevant information for example the{' '}
                        <span className='border py-0.5 px-1.5 border-rickshaw-lightgrey font-mono'>
                            customer
                        </span>{' '}
                        to get access to their details. We can also gather platform relevant data from your{' '}
                        <span className='border py-0.5 px-1.5 border-rickshaw-lightgrey font-mono'>
                            REST
                        </span>{' '}
                        APIs. A set of formatting{' '}
                        <span className='border py-0.5 px-1.5 border-rickshaw-lightgrey font-mono'>
                            functions
                        </span>{' '}
                        lets you construct the desired message for your customer.
                    </p>
                </li>
                <li>
                    <img src={data.delivery.publicURL} height={160} width={160} alt='Delivery' />
                    <h3 className='mt-4 text-4xl font-bold text-rickshaw-blue'>Deliver</h3>
                    <p className='my-4 text-base text-rickshaw-darkgrey'>
                        Link your{' '}
                        <span className='bg-rickshaw-background-red text-rickshaw-red border border-rickshaw-red rounded-lg py-0.5 px-2 font-mono'>
                            SendGrid
                        </span>{' '}
                        or{' '}
                        <span className='bg-rickshaw-background-red text-rickshaw-red border border-rickshaw-red rounded-lg py-0.5 px-2 font-mono'>
                            Twilio
                        </span>{' '}
                        account and take full advantage of their templating and delivery features. Rickshaw ensures a
                        timely and fault tolerant delivery via the gateway. Support for more service providers coming
                        soon.
                    </p>
                </li>
                <li>
                    <img src={data.report.publicURL} height={160} width={160} alt='Report' />
                    <h3 className='mt-4 text-4xl font-bold text-rickshaw-blue'>Report</h3>
                    <p className='my-4 text-base text-rickshaw-darkgrey'>
                        Rickshaw keeps a log of each delivery attempt, allowing you to measure, trace and troubleshoot
                        deliveries. Our beautiful Dashboards give you an oversight of your delivery workloads. Access
                        the data via our API for custom reporting.
                    </p>
                </li>
            </ul>
        </section>
    );
};
export default FeatureOverview;
